@import "vendors/font-awesome.scss";
@import "vendors/icofont.scss";
@import "vendors/themify/_themify-icons.scss";
@import "vendors/flag-icon.scss";
@import "vendors/feather-icon/feather-icon";
@import "vendors/whether-icon.scss";
@import "vendors/scrollbar.scss";
@import "vendors/animate.scss";
@import "vendors/prism.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "style";
@import "responsive";
@import "color-1.scss";
@import "vendors/dropzone.scss";
@import "./vendors/image-cropper.scss";
// @import "vendors/owlcarousel.scss";
// @import "vendors/icofont.scss";
@import "vendors/chartist.scss";

.vld-overlay.is-full-page {
  z-index: 10000 !important;
}

.form-control,
.multiselect {
  border: 1px solid #009881 !important;
}

.multiselect {
  border-radius: 0.375rem;
}

.select-form-control {
  border: 1px solid #009881 !important;
  width: 100%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  background-color: #fff;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  span {
  color: #fff !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  li
  a.active
  svg.stroke-icon {
  stroke: #fff !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link::after {
  background-color: #009881 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li.sidebar-list:hover
  > a:hover {
  background-color: #33ab99 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  svg {
  stroke: #fff !important;
}

.page-wrapper.compact-wrapper .sidebar-list i.fa-thumb-tack {
  color: #f48134 !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li:hover
  .sidebar-link:not(.active):hover
  .according-menu
  i {
  color: #fff !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a:hover {
  background-color: #33ab99 !important;
}

body.dark-sidebar
  .page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  .sidebar-list
  ul.sidebar-submenu
  li
  a.active {
  color: #fff !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-link.active
  .according-menu
  i {
  color: #fff !important;
}

.page-wrapper.compact-wrapper
  .page-body-wrapper
  div.sidebar-wrapper
  .sidebar-main
  .sidebar-links
  > li
  .sidebar-submenu
  li
  a::before {
  background-color: #f48134 !important;
}

.page-link.active[data-v-7b0ac738] {
  background-color: #009881 !important;
}

.border-tab.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #009881 !important;
  color: #009881 !important;
}

.logo img {
  width: 250px;
}

.border-tab.nav-tabs .nav-item .nav-link:hover {
  color: #009881 !important;
}

.appointment-table table tr td:first-child {
  width: auto !important;
}

#myDIV {
  padding-bottom: 50px !important;
}
